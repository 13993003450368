// Variables
@import "../../../sass/variables";

// メンテナンスモード時の表示
.maintenance {
  position: fixed;
  top: 145px;
  z-index: 9999;
  padding: 0.2rem 0.5rem;
  background-color: red;
  color: #fff;
}

.footer-sp {
  display: none;
}

.sub-footer-row {
  padding: 0;
  background-color: $theme-footer-middle-green;

  ul {
    padding-left: 5px;

    li {
      a {
        font-size: 12px;
      }
    }
  }

  .feature-column {
    flex-wrap: wrap;

    li {
      width: 50%;
    }
  }
}

.copy-right-row {
  background-color: $theme-footer-bottom-green;
}

/////////////////////////////////////////////////
/////////////// スマートフォン対応 ///////////////
@media (max-width: 767px) {
  .footer-pc {
    display: none;
  }

  .footer-sp {
    text-align: center;
    display: block !important;

    .sub-footer-row {
      padding: 15px 0 10px;

      ul {
        margin-bottom: 0;
        list-style-type: none;

        li {
          a {
            display: block;
            line-height: 1.5;
            text-align: left;
          }
          a:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }

    }
  }
}
